export const languages = [
    {
      key: 'python',
      name: 'Python',
      highlighter: 'language-python'
    },
    {
      key: 'javascript',
      name: 'Javascript',
      highlighter: 'language-javascript'
    },
    {
      key: 'typescript',
      name: 'Typescript',
      highlighter: 'language-typescript'
    },
    {
        key: 'php',
        name: 'PHP',
        highlighter: 'language-javascript'
    },
    {
      key: 'go',
      name: 'Golang',
      highlighter: 'language-go'
    },
    {
      key: 'rust',
      name: 'Rust',
      highlighter: 'language-rust'
    },
    {
      key: 'java',
      name: 'Java',
      highlighter: 'language-java'
    },
    {
        key: 'csharp',
        name: 'C#',
        highlighter: 'language-csharp'
    },
    {
      key: 'c',
      name: 'C',
      highlighter: 'language-c'
    },
    {
      key: 'cpp',
      name: 'C++',
      highlighter: 'language-cpp'
    },
    {
      key: 'bash',
      name: 'Bash',
      highlighter: 'language-bash'
    },
    {
        key: 'scala',
        name: 'Scala',
        highlighter: 'language-scala'
    },
    // {
    //   key: 'kotlin',
    //   name: 'Kotlin',
    //   highlighter: 'language-kotlin'
    // },
  ]